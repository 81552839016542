import React, { useEffect, useRef, useState } from "react";
import { RollingImagesInterface } from "../Interfaces/RollingImages.interface";
import useContentful from "../API/Hooks/hooks";
import { useNavigate } from "react-router-dom";

interface RollingImagesProps {}

const RollingImages: React.FC<RollingImagesProps> = () => {
  const [images, setImages] = useState<RollingImagesInterface[]>([]);
  const [duplicates, setDuplicates] = useState<number>(1);
  const marqueeContainerRef = useRef<HTMLDivElement>(null);
  const { getEntryFromContentful } = useContentful();
  const navigate = useNavigate();

  useEffect(() => {
    getEntryFromContentful("loopingImages").then((images) => {
      if (images) {
        const items = images.map((image) => image);
        setImages(items as any);
      }
    });
  }, []);

  const handleClick = () => {
    navigate('/ourWork')
  }

  useEffect(() => {
    if (images.length > 0 && marqueeContainerRef.current) {
      const updateDuplicates = () => {
        const containerWidth = marqueeContainerRef!.current!.offsetWidth;
        const imageWidth =
          marqueeContainerRef!.current!.querySelector("img")?.offsetWidth || 0;
        const totalImageWidth = images.length * imageWidth;
        const duplicatesNeeded = Math.ceil(containerWidth / totalImageWidth);

        if (duplicatesNeeded > 0) {
          setDuplicates(duplicatesNeeded + 1);
        }
      };

      const imagesLoaded = images.map(
        (image) =>
          new Promise<void>((resolve) => {
            const img = new Image();
            img.src = image?.fields?.image?.fields?.file?.url;
            img.onload = () => resolve();
          })
      );

      Promise.all(imagesLoaded).then(updateDuplicates);
    }
  }, [images]);

  if (!images.length) return null;
  if (duplicates <= 0) return null;
  return (
    <div className="marquee" ref={marqueeContainerRef}>
      <div className="marquee-content" onClick={handleClick}>
        {[...new Array(duplicates)].map((_, duplicateIndex) => (
          <React.Fragment key={duplicateIndex}>
            {images.map((image, index) => (
              <img
                key={`${duplicateIndex}-${index}`}
                src={image?.fields?.image?.fields?.file?.url}
                alt="rolling images"
                className="object-cover ml-4 mr-4"
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      <style>
        {`
          .marquee {
              height: 100px;
              width: 100%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              background-color: #6A2BE9;  
              padding: 8px 0 4px 0;
          }

          .marquee-content {
              display: flex;
              animation: marquee 20s linear infinite;
          }

          .marquee:hover .marquee-content {
              animation-play-state: paused;
          }

          @keyframes marquee {
              0% { transform: translateX(0); }
              100% { transform: translateX(-50%); }
          }
        `}
      </style>
    </div>
  );
};

export default RollingImages;
