import { useEffect, useState } from "react";
import useContentful from "../API/Hooks/hooks";
import { MainContactFooter } from "../Interfaces/MainContactFooter.interface";
import { ReactComponent as InstaLogo } from "../UI/Icons/InstagramLogo.svg";
import { ReactComponent as TelegramLogo } from "../UI/Icons/TelegramLogo.svg";
import { ReactComponent as FacebookLogo } from "../UI/Icons/FacebookLogo.svg";
import { ReactComponent as ViberLogo } from "../UI/Icons/ViberLogo.svg";
import { ReactComponent as LinkedInLogo } from "../UI/Icons/LinkedInLogo.svg";

interface MainContactCardProps {}

export const MainContactCard: React.FC<MainContactCardProps> = () => {
  const { getEntryFromContentful } = useContentful();
  const [mainContact, setMainContact] = useState<MainContactFooter>();
  useEffect(() => {
    getEntryFromContentful('mainContact').then((contact) => {
      const item = contact![0].fields;
      setMainContact(item as any);
    });
  }, []);
  return (
    <div className="flex flex-col md:flex-row md:items-end w-full md:w-1/2 justify-between">

        <div className="flex flex-col max-w-[245px] mb-6 md:mb-3.5">
            <div className="flex mb-[9px] items-center">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/flag-netherlands" clip-path="url(#clip0_2024_4363)">
                            <path id="Vector" d="M0 9.33301H24V14.6663H0V9.33301Z" fill="#EEEEEE"/>
                            <path id="Vector_2"
                                  d="M21.3333 3.33301H2.66667C1.95942 3.33301 1.28115 3.61396 0.781049 4.11406C0.280952 4.61415 0 5.29243 0 5.99967L0 9.33301H24V5.99967C24 5.29243 23.719 4.61415 23.219 4.11406C22.7189 3.61396 22.0406 3.33301 21.3333 3.33301Z"
                                  fill="#AE1F28"/>
                            <path id="Vector_3"
                                  d="M2.66667 20.667H21.3333C22.0406 20.667 22.7189 20.386 23.219 19.8859C23.719 19.3858 24 18.7076 24 18.0003V14.667H0V18.0003C0 18.7076 0.280952 19.3858 0.781049 19.8859C1.28115 20.386 1.95942 20.667 2.66667 20.667Z"
                                  fill="#20478B"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2024_4363">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="flex">
                    The Netherlands, Den Haag
                    Saturnusstraat 95, 2516AG
                </div>
            </div>
            <div className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/email">
                            <path id="Vector"
                                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">
                    <a href="mailto:info@devsupply.nl" className="flex">info@devsupply.nl</a>
                </div>
            </div>
            <a href="callto:+31684382110"
               className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/sharp-phone">
                            <path id="Vector"
                                  d="M21.0004 15.46L15.7304 14.85L13.2104 17.37C10.3716 15.9262 8.06417 13.6188 6.62039 10.78L9.15039 8.25L8.54039 3H3.03039C2.45039 13.18 10.8204 21.55 21.0004 20.97V15.46Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">+31684382110</div>
            </a>
        </div>

        <div className="flex flex-col max-w-[245px] mb-6 md:mb-3.5">
            <div className="flex mb-[9px] items-center">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/flag-sweden">
                            <path id="Vector"
                                  d="M10.3333 20.6663H21.3333C22.806 20.6663 24 19.4723 24 17.6663V13.6663H10.3333V20.6663ZM21.3333 3.33301H10.3333V10.333H24V5.99967C24 5.29243 23.719 4.61415 23.219 4.11406C22.7189 3.61396 22.0406 3.33301 21.3333 3.33301ZM7 3.33301H2.66667C1.95977 3.33301 1.28179 3.61368 0.781755 4.11335C0.281716 4.61301 0.000530173 5.29078 0 5.99767L0 10.333H7V3.33301ZM0 13.6663V17.669C0.00133333 19.4737 1.19467 20.6663 2.66667 20.6663H7V13.6663H0Z"
                                  fill="#006AA7"/>
                            <path id="Vector_2"
                                  d="M10.3333 3.33301H7V10.333H0V13.6663H7V20.6663H10.3333V13.6663H24V10.333H10.3333V3.33301Z"
                                  fill="#FECC00"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">
                    Sweden,<br />
                    Stockholm
                </div>
            </div>
            <div className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/email">
                            <path id="Vector"
                                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">
                    <a href="mailto:infose@devsupply.nl" className="flex">infose@devsupply.nl</a>
                </div>
            </div>
          {/*  <a href="callto:01234556777"
               className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/sharp-phone">
                            <path id="Vector"
                                  d="M21.0004 15.46L15.7304 14.85L13.2104 17.37C10.3716 15.9262 8.06417 13.6188 6.62039 10.78L9.15039 8.25L8.54039 3H3.03039C2.45039 13.18 10.8204 21.55 21.0004 20.97V15.46Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">01234556777</div>
            </a>*/}
        </div>

        <div className="flex flex-col max-w-[245px] mb-6 md:mb-3.5">
            <div className="flex mb-[9px] items-center">
                <div className="flex mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2024_4383)">
                            <path
                                d="M21.3333 3.33301H16V20.6663H21.3333C22.0406 20.6663 22.7189 20.3854 23.219 19.8853C23.719 19.3852 24 18.7069 24 17.9997V5.99967C24 5.29243 23.719 4.61415 23.219 4.11406C22.7189 3.61396 22.0406 3.33301 21.3333 3.33301Z"
                                fill="#DD2E44"/>
                            <path
                                d="M2.66667 3.33301C1.95942 3.33301 1.28115 3.61396 0.781049 4.11406C0.280952 4.61415 0 5.29243 0 5.99967L0 17.9997C0 18.7069 0.280952 19.3852 0.781049 19.8853C1.28115 20.3854 1.95942 20.6663 2.66667 20.6663H8V3.33301H2.66667Z"
                                fill="#265FB5"/>
                            <path d="M8 3.33301H16V20.6663H8V3.33301Z" fill="#FFCC4D"/>
                            <path d="M10.667 10H13.3337V12H10.667V10Z" fill="#DD2E44"/>
                            <path d="M10.667 12V13.3333L12.0003 14L13.3337 13.3333V12H10.667Z" fill="#226699"/>
                            <path
                                d="M10.667 7.33301V7.99967H11.3337V9.33301H12.667V7.33301H10.667ZM12.667 13.9997L12.0003 14.4163L11.3337 13.9997L10.667 15.9997L12.0003 16.6663L13.3337 15.9997L12.667 13.9997ZM10.0003 8.66634L9.33366 7.99967L8.66699 8.66634V15.333L10.0003 13.9997V9.33301H10.667L10.0003 8.66634ZM14.667 7.99967L14.0003 8.66634L13.3337 9.33301H14.0003V13.9997L15.3337 15.333V8.66634L14.667 7.99967Z"
                                fill="#A0724A"/>
                            <path
                                d="M10.8223 11.7295H11.1763V12.0002H10.8223V11.7295ZM12.8223 11.7295H13.1763V12.0002H12.8223V11.7295Z"
                                fill="#FFCC4D"/>
                            <path
                                d="M10.4251 15.1618C10.3545 15.2031 10.2498 15.1551 10.1918 15.0558L8.70382 12.4784C8.64648 12.3784 8.65715 12.2644 8.72848 12.2231L8.81915 12.1711C8.88982 12.1298 8.99448 12.1778 9.05182 12.2771L10.5398 14.8551C10.5978 14.9544 10.5865 15.0684 10.5158 15.1098L10.4251 15.1618Z"
                                fill="#77B255"/>
                            <path
                                d="M15.2111 12.2617C15.2824 12.3024 15.2944 12.415 15.2371 12.513L13.7764 15.045C13.7191 15.143 13.6164 15.189 13.5444 15.1484L13.4544 15.0964C13.3837 15.055 13.3717 14.943 13.4284 14.8444L14.8897 12.3124C14.9464 12.215 15.0497 12.1677 15.1211 12.2097L15.2111 12.2617Z"
                                fill="#FFCC4D"/>
                            <path
                                d="M10.6667 14L10 14.6667V15.3333L10.6667 14.6667V14ZM13.3333 14V14.6667L14 15.3333V14.6667L13.3333 14Z"
                                fill="#DD2E44"/>
                            <path
                                d="M11.9997 11.3337L11.333 10.667V12.639L11.9997 13.3337L12.6663 12.639V10.667L11.9997 11.3337Z"
                                fill="#FFCC4D"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2024_4383">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="flex">
                    Moldova,<br />
                    Chisinau
                </div>
            </div>
            <div className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/email">
                            <path id="Vector"
                                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">
                    <a href="mailto:infomd@devsupply.nl" className="flex">infomd@devsupply.nl</a>
                </div>
            </div>
            {/*<a href="callto:01234556777"
               className="flex mb-[9px] items-center hover:text-violet-700 transition">
                <div className="flex mr-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon/sharp-phone">
                            <path id="Vector"
                                  d="M21.0004 15.46L15.7304 14.85L13.2104 17.37C10.3716 15.9262 8.06417 13.6188 6.62039 10.78L9.15039 8.25L8.54039 3H3.03039C2.45039 13.18 10.8204 21.55 21.0004 20.97V15.46Z"
                                  fill="#6A2BE9"/>
                        </g>
                    </svg>
                </div>
                <div className="flex">01234556777</div>
            </a>*/}
        </div>

    </div>
  );
};
