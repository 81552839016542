import React, {useEffect, useState} from "react";
import useContentful from "../API/Hooks/hooks";

interface FooterTextProps {
}

export const FooterText: React.FC<FooterTextProps> = ({}) => {
    const [parts, setParts] = useState<string[]>([]);
    const [color, setColor] = useState<string>("");
    const {getEntryFromContentful} = useContentful();
    useEffect(() => {
        getEntryFromContentful("footerText").then((entry) => {
            const item = entry![0].fields;
            setColor(item.color as string);
            setParts((item.text as string).split("|"));
        });
    }, []);
    return (
        <div className="w-fit flex flex-col justify-center items-center absolute md:relative invisible md:visible">
            {parts.map((part, index) => {
                if (part.startsWith("<color>") && part.endsWith("</color>")) {
                    return (
                        <span
                            key={index}
                            className="w-full text-left text-sm lg:text-lg"
                            style={{
                                color: `#${color}`,
                            }}
                        >
                  {part.substring(7, part.length - 8)}
                </span>
                    );
                } else {
                    return (
                        <span
                            className="w-full text-left text-sm lg:text-lg"
                            key={index}
                        >
                  {part}
                </span>
                    );
                }
            })}
        </div>
    );
};
