import React from "react";
import ButtonComponent, { ButtonType } from "../UI/Buttons/ButtonComponent";
import { useNavigate } from "react-router-dom";

interface CompanyCardProps {
  name: string;
  description: string;
  problem: string;
  solution: string;
  image: string;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({
  name,
  description,
  image,
  problem,
  solution
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/ourWork/${name}`, { state: { name, imageUrl: image, problem, solution } });
  };
  return (
    <div className="w-full md:max-w-[229px] lg:max-w-[440px] aspect-square flex flex-col gap-3">
      <img
        src={image}
        alt={name}
        className="w-full md:max-w-[229px] lg:max-w-[440px] object-cover border-[1px] border-gray-400"
      />
      <div className="w-full flex flex-row justify-between items-center gap-6">
        <div className="w-full flex flex-col justify-between items-center">
          <h1 className="w-full text-left text-base lg:text-2xl font-bold">{name}</h1>
          <p className="w-full text-left text-sm lg:text-lg text-gray-400">{description}</p>
        </div>
        <ButtonComponent isLocal={true} text="" type={ButtonType.ARROW_MOBILE} isLink={false} link="" onClick={() => handleClick()} />
      </div>
    </div>
  );
};
