import React from "react";
import {Link, useLocation} from "react-router-dom";
import {ReactComponent as CopyrightLogo} from "../UI/Icons/CopyrightLogo.svg";

interface ArticlesFooterProps {
}

export const ArticlesFooter: React.FC<ArticlesFooterProps> = () => {
    const location = useLocation();

    const getLinkClass = (path: string) =>
        location.pathname === path ? "text-black underline" : "text-black";

    return (
        <div className="w-full md:w-fit mb-6 md:mb-0 text-base">
            <ul>
                {/*<li className="h-[44px] flex flex-row justify-start items-center">*/}
                {/*  <Link to="/aboutUs" className={`hover:underline ${getLinkClass("/aboutUs")}`}>*/}
                {/*    About Us*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li className="h-[44px] flex flex-row justify-start items-center">
                    <Link to="/ourServices" className={`hover:underline ${getLinkClass("/ourServices")}`}>
                        Our Services
                    </Link>
                </li>
                <li className="h-[44px] flex flex-row justify-start items-center">
                    <Link to="/ourPrices" className={`hover:underline ${getLinkClass("/ourPrices")}`}>
                        Prices
                    </Link>
                </li>
                <li className="h-[44px] flex flex-row justify-start items-center">
                    <Link to="/forStartups" className={`hover:underline ${getLinkClass("/forStartups")}`}>
                        For Startups
                    </Link>
                </li>
                <li className="h-[44px] flex flex-row justify-start items-center"></li>
                {/*<li className="h-[44px] flex flex-row justify-start items-center">*/}
                {/*  <Link to="/ourWork" className={`hover:underline ${getLinkClass("/ourWork")}`}>*/}
                {/*    Our Work*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className="h-[44px] flex flex-row justify-start items-center">*/}
                {/*  <Link to="/howWeWork" className={`hover:underline ${getLinkClass("/howWeWork")}`}>*/}
                {/*    How We Work*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className="h-[44px] flex flex-row justify-start items-center">*/}
                {/*  <Link to="/careers" className={`hover:underline ${getLinkClass("/careers")}`}>*/}
                {/*    Careers*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li className="h-[44px] flex flex-row justify-start items-center">
                    DevSupply.nl <CopyrightLogo/> 2024
                </li>
            </ul>

        </div>
    );
};
