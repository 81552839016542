import React, {useEffect} from "react";
import "./index.css";
import Header from "./Widgets/Header";
import {HomePage} from "./Pages/Homepage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Footer} from "./Widgets/Footer";
import {AboutUsPage} from "./Pages/AboutUsPage";
import {OurServicesPage} from "./Pages/OurServicesPage";
import {OurWorkPage} from "./Pages/OurWorkPage";
import {HowWeWorkPage} from "./Pages/HowWeWorkPage";
import {CareersPage} from "./Pages/CareersPage";
import {CompanyPage} from "./Pages/CompanyPage";
import ReactGA from 'react-ga4';
import {inject} from '@vercel/analytics';
import {StartupsPage} from "./Pages/StartupsPage";
import Pricingpage from "./Pages/PricingPage/PricingPage";
import { Helmet } from 'react-helmet';

inject();

ReactGA.initialize('G-Q194PGXJTP');

function App() {

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname});
    }, []);

    // You can use the 'useEffect' hook to track specific user actions like as well.
    // For instance, you can track button clicks by adding the following code:
    const handleButtonClick = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked a button'
        });
    };

    return (
        <BrowserRouter>
            <Helmet>
                <title>DevSupply</title>
                <meta name="description" content="Hire pre-vetted developers fro Fintech Startups, AI-Powered Vetting"/>
                <meta name="keywords" content="pre-vetted developers, AI-Powered Vetting, DevScout AI Interviewer"/>
                <meta name="author" content="DevSupply & Co"/>
                <meta property="og:title" content="DevSupply"/>
                <meta property="og:description" content="Hire pre-vetted developers for Fintech Startups, AI-Powered Vetting"/>
                {/*<meta property="og:image" content="https://example.com/image.jpg"/>*/}
                <meta property="og:url" content="devsupply.nl"/>
                <meta name="twitter:title" content="DevSupply"/>
                <meta name="twitter:description" content="Hire pre-vetted developers for Fintech Startups, AI-Powered Vetting"/>
                {/*<meta name="twitter:image" content="https://example.com/image.jpg"/>*/}
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="flex flex-col container mx-auto max-w-[1440px]">
                <Header/>
                <main className="flex flex-col justify-center items-center gap-6">
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        {/*<Route path="/aboutUs" element={<AboutUsPage/>}/>*/}
                        <Route path="/ourServices" element={<OurServicesPage/>}/>
                        <Route path="/ourWork" element={<OurWorkPage/>}/>
                        <Route path="/pricing" element={<Pricingpage/>}/>
                        {/*<Route path="/howWeWork" element={<HowWeWorkPage/>}/>*/}
                        {/*<Route path="/careers" element={<CareersPage/>}/>*/}
                        {/*<Route path="/ourWork/:name" element={<CompanyPage/>}/>*/}
                        <Route path="/forStartups" element={<StartupsPage/>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
