import React, {useEffect} from 'react';
import ButtonComponent, {ButtonType} from "../../UI/Buttons/ButtonComponent";
import {CardDetails, CardItem} from "../StartupsPage";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export type PricingpageProps = {}

export default function Pricingpage({}: PricingpageProps) {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useGSAP(
        () => {
            // gsap code here...
            const cardItems = gsap.utils.toArray(".slide-up-card");

            //  initialization
            const createScrollTrigger = (item: any, index: number) => {
                const st = ScrollTrigger.create({
                    trigger: item,
                    start: "top 100+=" + item.getAttribute("data-animation-item"),
                    endTrigger: `.slide-pusher-${index}`,
                    end: "top 400",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    markers: false
                });

                return gsap.timeline({st});
            };

            // start
            cardItems.forEach((item: any, index) => {
                let tll = createScrollTrigger(item, index);

                tll.to(item, {
                    transformOrigin: "center center"
                });
            });
        },
    );

    return (
        <div className="PricingPage w-full px-4 lg-px-0">

            <div className="w-full flex flex-col lg:flex-row justify-between mb-24">
                <h1 className="w-full text-left text-[40px] md:text-[80px] max-w-[900px] w-3/5 align-center">
                    Value-Driven, affordable pricing for <span className="text-violet-700">growing fintech</span>
                </h1>

                <div className="flex w-full lg:max-w-[390px] min-h-[300px] lg:w-2/5 lg:justify-end">
                    <iframe
                        style={{ width: "100%", height: "400px" }}
                        src="https://lottie.host/embed/e75e6ae9-695b-4633-9efd-3e81fecddf94/GADL3ePryP.json"></iframe>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row align-bottom w-full justify-between text-left mb-[30vh]">

                <p className="w-full text-left text-base justify-self-start lg:text-2xl mb-6 lg:mb-0 max-w-[700px] md:w-1/2 lg:w-3/5">
                    We focus on delivering high-value talent solutions at a price lower than traditional recruitment services, making it easier for startups to scale without the heavy cost.
                </p>

                <ButtonComponent
                    isDisabled={false}
                    classNames="flex mt-auto lg:w-[330px]"
                    isLocal={true}
                    isLink={true}
                    type={ButtonType.PRIMARY}
                    text="Start Now"
                    link="/howWeWork"
                />
            </div>

            {/* Section: Pay Per Hire */}
            <div className="slide-up-card flex w-full mb-[300px] lg:min-h-[80vh] bg-white lg:bg-transparent"
                 data-animation-item="0">
                <div className="flex flex-col lg:flex-row justify-between w-full"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 container-title"><span className="inline text-violet-700">Pay-Per-Hire&nbsp;</span> Model:</div>
                    <div className="flex flex-col w-full lg:w-1/2 items-end">

                        <CardItem
                            title="For startups"
                            indexKey={0}
                            data-animation-item="0px"
                            description="Ideal for startups with occasional hiring needs—12% of the annual salary per successful hire."
                            classNames="box-1 bg-[#BCAFFF]"/>

                    </div>
                </div>
            </div>

            {/* Section: Value-Based Pricing */}
            <div className="slide-up-card slide-pusher-0 flex w-full mb-[300px] lg:min-h-[80vh] bg-white pt-20 lg:pt-0"
                 data-animation-item="0">
                <div className="flex flex-col lg:flex-row justify-between w-full"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 container-title"><span className="inline text-violet-700">Value-Based &nbsp;</span> Pricing:</div>
                    <div className="flex flex-col w-full lg:w-1/2 items-end">

                        <CardItem
                            title="For rapidly scaling startups"
                            indexKey={0}
                            data-animation-item="0px"
                            description="Pricing is based on the value we bring to your growth and hiring demands."
                            classNames="box-1 bg-[#EBE7FF]"/>

                    </div>
                </div>
            </div>

            {/* Section: Pay in Installments */}
            <div className="slide-up-card slide-pusher-1 flex w-full mb-[300px] lg:min-h-[80vh] bg-white pt-20 lg:pt-0"
                 data-animation-item="0">
                <div className="flex flex-col lg:flex-row justify-between w-full"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 container-title text-violet-700">Pay in Installments:</div>
                    <div className="flex flex-col w-full lg:w-1/2 items-end">

                        <CardItem
                            title="For fintech startups"
                            indexKey={0}
                            data-animation-item="0px"
                            description="Flexible pricing for fintech startups with limited funds. We’re open to discussing a model that supports your growth goals while spreading costs over time."
                            classNames="box-1 bg-[#F3F2FF]"/>

                    </div>
                </div>
            </div>

            <div className="card-pusher slide-up-card slide-pusher-2 flex flex-col lg:min-h-[40vh] bg-white pt-40 lg:pt-0 bg-white">
                <div className="w-full lg:w-2/4 text-left text-[40px] md:text-[80px] lg:text-[80px] text-violet-700 mb-20">3-Month Rehire Guarantee</div>
                <div className="flex flex-col lg:flex-row justify-between items-end">
                    <div className="max-w-[780px] text-2xl mb-6 lg:mb-0">We stand by the quality of the talent we provide. If your hired developer doesn’t meet your
                        expectations within the first 3 months, <span className="inline-flex text-violet-700">we’ll find a replacement for you at no additional cost.</span>
                        This guarantee ensures you can focus on growing your fintech business with confidence, knowing that
                        you’re backed by a team committed to your success.</div>

                    <ButtonComponent
                        isDisabled={false}
                        classNames="flex lg:w-[330px] align-self-end"
                        isLocal={true}
                        isLink={true}
                        type={ButtonType.PRIMARY}
                        text="Start Now"
                        link="/howWeWork"
                    />
                </div>

            </div>

        </div>
    );
}
