import React from "react";
import { redirect } from "react-router-dom";

export enum ButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  SECONDARY_MINI = "SECONDARY_MINI",
  ARROW = "ARROW",
  ARROW_MOBILE = "ARROW_MOBILE",
}

interface ButtonComponentProps {
  text: string;
  link: string;
  type: ButtonType;
  isLocal: boolean;
  classNames?: string;
  isDisabled?: boolean;
  isLink?: boolean;
  onClick?: () => void;
}

const ArrowIcon: React.FC = () => (
  <svg
    width={46}
    height={46}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23" cy="23" r="22.5" stroke="currentColor" />
    <path
      stroke="currentColor"
      d="M31.3536 23.3536C31.5488 23.1583 31.5488 22.8417 31.3536 22.6464L28.1716 19.4645C27.9763 19.2692 27.6597 19.2692 27.4645 19.4645C27.2692 19.6597 27.2692 19.9763 27.4645 20.1716L30.2929 23L27.4645 25.8284C27.2692 26.0237 27.2692 26.3403 27.4645 26.5355C27.6597 26.7308 27.9763 26.7308 28.1716 26.5355L31.3536 23.3536ZM15 23.5H31V22.5H15V23.5Z"
      fill="#131314"
    />
  </svg>
);

const ArrowMobileIcon: React.FC = () => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23" cy="23" r="22.5" stroke="currentColor" />
    <path
      className=""
      stroke="currentColor"
      d="M31.3536 23.3536C31.5488 23.1583 31.5488 22.8417 31.3536 22.6464L28.1716 19.4645C27.9763 19.2692 27.6597 19.2692 27.4645 19.4645C27.2692 19.6597 27.2692 19.9763 27.4645 20.1716L30.2929 23L27.4645 25.8284C27.2692 26.0237 27.2692 26.3403 27.4645 26.5355C27.6597 26.7308 27.9763 26.7308 28.1716 26.5355L31.3536 23.3536ZM15 23.5H31V22.5H15V23.5Z"
      fill="#131314"
    />
  </svg>
);

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  text,
  link,
  type,
  isDisabled,
  onClick,
  isLocal,
  isLink,
  classNames
}) => {
  const handleClick = () => {
    if (isLink) {
      if (isLocal) {
        window.location.href = link
      } else {
        window.open(link, '_blank')
      }
    } else {
      onClick && onClick();
    }
  };

  const buttonClassNames = {
    [ButtonType.PRIMARY]:
      "lg:w-52 md:w-56 w-full h-11 lg:h-12 flex justify-center items-center bg-violet-700 text-white drop-shadow-md hover:bg-violet-400 disabled:bg-gray-400 disabled:cursor-not-allowed",
    [ButtonType.SECONDARY]:
      "lg:w-52 md:w-56 w-full h-11 lg:h-12 flex justify-center items-center bg-white text-black border-2 hover:border-violet-700 disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed",
    [ButtonType.SECONDARY_MINI]:
      "lg:w-52 md:w-56 w-full h-11 lg:h-12 flex justify-center items-center bg-transparent text-black underline hover:text-violet-700 drop-shadow-md disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed",
    [ButtonType.ARROW]:
      "hover:text-violet-700 disabled:text-gray-400 disabled:cursor-not-allowed cursor-pointer",
    [ButtonType.ARROW_MOBILE]:
      "hover:text-violet-700 disabled:text-gray-400 disabled:cursor-not-allowed cursor-pointer",
  };

  if (type === ButtonType.ARROW) {
    return (
      <div className={buttonClassNames[type] + ' ' + classNames}>
        <ArrowIcon />
      </div>
    );
  }

  if (type === ButtonType.ARROW_MOBILE) {
    return (
      <div className={buttonClassNames[type] + ' ' + classNames} onClick={handleClick}>
        <ArrowMobileIcon />
      </div>
    );
  }

  return (
    <button
      disabled={isDisabled}
      className={buttonClassNames[type] + ' ' + classNames}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default ButtonComponent;
