import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID!,
    accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_API_KEY!,
    host: process.env.REACT_APP_CONTENTFUL_HOST!,
  });

  const getEntryFromContentful = async (contentType: string) => {
    try {
      const response = await client.getEntries({
        content_type: contentType,
        select: ["fields"],
      });

      const entry = response.items;

      return entry;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getEntryFromContentful,
  };
};

export default useContentful;
