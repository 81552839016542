import React from "react";
interface WhyDevSupplyProps {
  title: string;
  text: string;
  imageUrl: string;
}

export const WhyDevSupplyCard: React.FC<WhyDevSupplyProps> = ({
  title,
  text,
  imageUrl,
}) => {
  return (
    <div className="box-border border border-gray-400 flex flex-col w-full md:flex-row justify-center md:justify-between items-start">
      <div className="flex flex-col gap-2 md:w-1/2 h-full p-4 justify-between content-stretch">
        <p className="flex text-2xl lg:text-4xl w-full text-left font-bold h-full">
          {title}
        </p>
        <p className="flex text-base md:text-sm text-gray-400 h-full">{text}</p>
      </div>
      <div className="relative w-full max-h-40 md:max-h-36 md:w-1/2 justify-self-end pr-4 pb-4 pl-4 pt-0 lg:p-0">
        <img
          className="md:h-36 object-cover max-w-[100%] md:max-w-[440px] ml-auto"
          src={imageUrl}
          alt="Why DevSupply"
        />
      </div>
    </div>
  );
};
