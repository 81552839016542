import React, {useEffect, useState} from "react";
import {WhyDevSupplyInterface} from "../../Interfaces/WhyDevSupply.interface";
import {WhyDevSupplyCard} from "../../Components/WhyDevSupplyCard";
import useContentful from "../../API/Hooks/hooks";

interface WhyDevSupplyProps {
}

export const WhyDevSupply: React.FC<WhyDevSupplyProps> = () => {
    useEffect(() => {
    }, []);

    const defaultPoints = [
        {
            title: "Simplified hiring process",
            text: "We’ve cut out unnecessary steps to speed up your hiring. Through our AI-powered shortlisting, you can access developers in minutes, reducing time spent on sourcing.",
            src: "/hashtag-speed.svg"
        },
        {
            title: "Transparent and fair costs",
            text: "Our pricing is clear from the start. No hidden fees, just access to top-tier developers at a fraction of the usual recruitment cost.",
            src: "/hashtag-afford.svg"
        },
        {
            title: "Top 1% of developers",
            text: "Our AI vetting ensures you only see developers with the right skills and experience, giving you access to talent that's proven and reliable.",
            src: "/hashtag-quality.svg"
        },
        {
            title: "Tailored talent for your needs",
            text: "We match you with developers who not only fit your technical needs but also align with your company’s culture, so you build the perfect team for growth.",
            src: "/hashtag-flexibility.svg"
        }
    ]


    return (
        <div className="flex flex-col w-full h-fit mb-40 gap-6 lg:pr-10 lg:pl-10 pl-4 pr-4 mt-4 md:mt-6 lg:mt-9">
            <p className="text-[42px] lg:text-[100px] md:text-7xl lg:text-9xl mb-9 mt-16 md:mt-0 text-left w-full">
                Why DevSupply
            </p>
            {defaultPoints?.map((point, index) => {
                return (
                    <WhyDevSupplyCard
                        key={index}
                        title={point.title}
                        text={point.text}
                        imageUrl={point.src}
                    />
                );
            })}
        </div>
    );
};
