import React, { useEffect } from "react";
import { CompanyList } from "../Widgets/ourWork/CompanysList";
import RollingImages from "../Components/RollingImages";
interface OurWorkPageProps {}

export const OurWorkPage: React.FC<OurWorkPageProps> = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className="flex flex-col w-full h-auto justify-center items-center gap-6">
      <CompanyList />
      <RollingImages />
    </div>
  );
};
