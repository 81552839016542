import React, { useEffect, useState } from "react";
import { CompanyCardInterface } from "../../Interfaces/CompanyCard.interface";
import useContentful from "../../API/Hooks/hooks";
import { CompanyCard } from "../../Components/CompanyCard";
import ButtonComponent, { ButtonType } from "../../UI/Buttons/ButtonComponent";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

interface CompanyListProps {}

export const CompanyList: React.FC<CompanyListProps> = () => {
  const [companies, setCompanies] = useState<CompanyCardInterface[]>([]);
  const { getEntryFromContentful } = useContentful();
  const [currentPage, setCurrentPage] = useState(1);
  const companiesPerPage = 6;
  useEffect(() => {
    getEntryFromContentful("ourWorks").then((companies) => {
      const items = companies!.map((company) => company?.fields);
      setCompanies(items as any);
    });
  }, []);
  const totalPages = Math.ceil(companies.length / companiesPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * companiesPerPage;
  const currentCompanies = companies.slice(
    startIndex,
    startIndex + companiesPerPage
  );
  if (!currentCompanies.length) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center gap-9 lg:pr-10 lg:pl-10 pl-4 pr-4">
      <h1 className=" text-[40px] md:text-[80px] lg:text-[120px] w-full text-left">
        Our Works
      </h1>
      <div className="w-full max-w-[1440px] h-full flex flex-col md:flex-row flex-wrap justify-center items-center gap-2">
        {[1, 2, 3, 4, 5, 6].map((el, index) => (
          <div key={index} className="animate-pulse w-full md:max-w-[229px] lg:max-w-[440px] aspect-square flex flex-col gap-3">
          <div className="w-full md:max-w-[229px] lg:max-w-[440px] h-full bg-gray-300"></div>
          <div className="w-full flex flex-row justify-between items-center gap-6">
            <div className="w-full flex flex-col justify-between items-center">
              <div className="w-full h-6 bg-gray-300"></div>
              <div className="w-full h-4 bg-gray-300 mt-2"></div>
            </div>
            <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
          </div>
        </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center w-full mt-4 gap-6">
        <ButtonComponent
          isLink={false}
          isLocal={true}
          onClick={handlePreviousPage}
          isDisabled={currentPage === 1}
          type={ButtonType.SECONDARY}
          text="Previous"
          link=""
        />
        <ButtonComponent
          isLink={false}
          onClick={handleNextPage}
          isLocal={true}
          isDisabled={currentPage === totalPages}
          type={ButtonType.SECONDARY}
          text="Next"
          link=""
        />
      </div>
    </div>
    )
  }
  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-9 lg:pr-10 lg:pl-10 pl-4 pr-4">
      <h1 className=" text-[40px] md:text-[80px] lg:text-[120px] w-full text-left">
        Our Works
      </h1>
      <div className="w-full max-w-[1440px] h-full flex flex-col md:flex-row flex-wrap justify-center items-center gap-2">
        {currentCompanies.map((company, index) => (
          <CompanyCard
            key={index}
            name={company.name}
            problem={company.problem}
            solution={company.solution}
            description={company.description}
            image={company?.image?.fields?.file?.url}
          />
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center w-full mt-4 gap-6">
        <ButtonComponent
          isLink={false}
          isLocal={true}
          onClick={handlePreviousPage}
          isDisabled={currentPage === 1}
          type={ButtonType.SECONDARY}
          text="Previous"
          link=""
        />
        <ButtonComponent
          isLink={false}
          onClick={handleNextPage}
          isLocal={true}
          isDisabled={currentPage === totalPages}
          type={ButtonType.SECONDARY}
          text="Next"
          link=""
        />
      </div>
    </div>
  );
};
